.form-control {
    margin-top: 1rem;
  }

.top-text {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.symptom-summary {
    margin-top: 2rem;
}
  
.info-table {
    margin-top: 3rem;
}

table {
    margin-top: 1rem;
}

[type='radio'] {
    display: none; 
}

.Btn-Blue-BG {
    background-color: lightskyblue !important;
}

.Btn-Blue-BG:focus {
    background-color: brown !important;
}