.NavItem:active {
height: 100%;
align-items: center;
font-weight: 800;
display: flex;
background-color: #74cf78;
}

.nav-pills .nav-link.active {
    background-color: #61fb69;
}
